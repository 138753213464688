import { contentTypes } from '../_constants'
import { BrowserDetect } from '../_helpers/utils'

const initialState = {
    map: '',
    locations: [],
    programs: [],
    pois: [],
    zoomify_url: '',
    map_small: '',
    ie: BrowserDetect() === 'Explorer',
    error: false,
    activePOI: {},
    activeFilters: [],
    currentFilters: [],
    previousFilters: [],
    showSidePanel: false,
    showInfoPanel: false,
    showProgramsPanel: false,
    showFilterBtn: false,
    showLinksMenu: false,
    darkMode: false,
    showMiniMap: true,
    showInfoScroll: [
        `translate(0px, 0px) scale(1)`,
        `360px`,
        `rgb(42, 31, 88) 0px 1px 0px 2px`,
        `1`,
        `translate(0,0px)`,
        `0`,
        `translate(0,15px)`,
        `rotate(0)`,
        false,
    ],
    mapZoom: ['', 3],
    map_height: 0,
    map_width: 0,
}

export const content = (state=initialState, action) => {
    switch (action.type) {
        case contentTypes.DATA_SUCCESS:
            const filterList = action.payload.programs.map(e => e.name)
            return {
                ...state,
                ...action.payload,
                activeFilters: [...filterList],
                currentFilters: [...filterList],
                previousFilters: [...filterList],
                error: false,
            }
        case contentTypes.PAGE_DATA_FAILURE:
            return {
                ...state,
                error: true,
            }
        case contentTypes.TOGGLE_INFO:
            return {
                ...state,
                activePOI: state.pois[action.payload] || {},
            }
        case contentTypes.TOGGLE_INFO_SCROLL:
            return {
                ...state,
                showInfoScroll: action.payload,
            }
        case contentTypes.UPDATE_MAP_ZOOM:
            return {
                ...state,
                mapZoom: action.payload,
            }
        case contentTypes.UPDATE_FILTER:
            return {
                ...state,
                activeFilters: [...action.payload],
                currentFilters: [...action.payload],
            }
        case contentTypes.TOGGLE_SIDE_PANEL:
            return {
                ...state,
                showSidePanel: action.payload,
            }
        case contentTypes.TOGGLE_INFO_PANEL:
            return {
                ...state,
                showInfoPanel: action.payload,
            }
        case contentTypes.TOGGLE_PROGRAMS_PANEL:
            return {
                ...state,
                showProgramsPanel: action.payload,
            }
        case contentTypes.TOGGLE_FILTER_BTN:
            return {
                ...state,
                showFilterBtn: action.payload,
            }
        case contentTypes.TOGGLE_LINKS_MENU:
            return {
                ...state,
                showLinksMenu: action.payload,
            }
        case contentTypes.TOGGLE_DARK_MODE:
            return {
                ...state,
                darkMode: action.payload,
            }
        case contentTypes.TOGGLE_MINI_MAP:
            return {
                ...state,
                showMiniMap: action.payload,
            }
        default:
            return state
    }
}
