import React, { Suspense } from 'react'

import googleAnalytics from '@analytics/google-analytics'
import Analytics from 'analytics'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { Router } from 'react-router-dom'
import { AnalyticsProvider } from 'use-analytics'

import { store, history } from './_helpers'
import { App } from './App'


const analytics = Analytics({
    plugins: [
        googleAnalytics({
            trackingId: 'UA-1234567',
        })
    ]
})

const Loading = () => {
    return (
        <div/>
    )
}

render(
    <Provider store={ store }>
        <AnalyticsProvider instance={analytics}>
            <Suspense fallback={<Loading />}>
                <Router history={history}>
                    <App />
                </Router>
            </Suspense>
        </AnalyticsProvider>
    </Provider>,
    document.getElementById('root')
)
