import React, { lazy } from 'react'

import Modal from 'react-modal'
import { useSelector, shallowEqual } from 'react-redux'
import { Route, Switch, Redirect } from 'react-router-dom'
import styled from 'styled-components'

import './css/App.scss'


const Map = lazy(() => import(/* webpackChunkName: "Map" */ './Components/Map/MapArea'))
const IEModal = lazy(() => import(/* webpackChunkName: "IEModal" */ './Components/Global/IEModal'))

Modal.setAppElement('#root')

export const App = () => {
    const { ie } = useSelector(state => state.content, shallowEqual)

    return (
        <MainPage>
            <Switch>
                <Route exact path='/' component={Map} />
                <Route exact strict path='/:url*' render={props => <Redirect to={`${props.location.pathname}/`} />} />
                <Route exact path='/:slug/' component={Map} />
            </Switch>
            {ie ? <IEModal /> : ''}
        </MainPage>
    )
}

const MainPage = styled.div`
    &:before {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        position: fixed;
        z-index: 9999;
        background: url('../static/images/noise.png?v=444444') repeat 0 0/50px 50px;
        opacity: 0.15;
        mix-blend-mode: soft-light;
        pointer-events: none;
    }
`
