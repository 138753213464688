import { createConstants } from '../_helpers/utils'

export const contentTypes = createConstants(
    'DATA_REQUEST',
    'DATA_SUCCESS',
    'DATA_FAILURE',

    'TOGGLE_INFO',
    'TOGGLE_INFO_SCROLL',
    'UPDATE_MAP_ZOOM',
    'UPDATE_FILTER',
    'UPDATE_FILTER_DISPLAY',
    'TOGGLE_SIDE_PANEL',
    'TOGGLE_INFO_PANEL',
    'TOGGLE_PROGRAMS_PANEL',
    'TOGGLE_FILTER_BTN',
    'TOGGLE_LINKS_MENU',
    'TOGGLE_DARK_MODE',
    'TOGGLE_MINI_MAP',
)
